<template>
  <div class="row" id="tut-box-filtros">
    <div class="col-12 d-flex">
      <div class="card flex-fill">
        <div class="card-body p-0 box-dashboard">
          <div class="align-self-center">
            <form class="filtro_especie" @submit.prevent="onexportDocx">
              <div class="row g-3 p-3 pb-0" >
                <div class="col-12">
                  <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" :value="1" v-model="filter.species_type">
                      <label class="form-check-label" for="inlineRadio1">{{ $t('label.invasoras') }}</label>
                  </div>
                  <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" :value="2" v-model="filter.species_type">
                      <label class="form-check-label" for="inlineRadio2">{{ $t('label.contidasBrasil') }}</label>
                  </div>
                  <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" :value="3" v-model="filter.species_type">
                      <label class="form-check-label" for="inlineRadio3">{{ $t('label.ausentesBrasil') }}</label>
                  </div>
                  <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" :value="4" v-model="filter.species_type">
                      <label class="form-check-label" for="inlineRadio4">{{ $t('label.deficiencia') }}</label>
                  </div>
                </div>
              </div>
              <div class="row g-3 p-3 pb-0">
                <div class="col-12 col-lg-3" id="tut-filtro-select1">
                  <v-select :appendToBody="true" :options="listThemes" v-model="filter.theme" label="text" index="value"
                    :reduce="r => r.value" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <!-- NOME CIENTIFICO -->
                <div class="col-12 col-lg-7" v-if="filter.theme === 1">
                  <v-select multiple :appendToBody="true" :filterable="false" @search="getSpecies" :options="speciesList" v-model="filter.specieSelected" label="scientific_name" index="species_id"
                    :reduce="r => r.species_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.scientific_name}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.scientific_name}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <!-- NOME CIENTIFICO -->

                <!-- TAXONOMIA -->
                <div class="col-12 col-lg-4" v-if="filter.theme === 2">
                  <v-select :appendToBody="true" :options="listTaxonomy" v-model="filter.fieldTaxonomy" label="text" index="value"
                    :reduce="r => r.value" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 2 && filter.fieldTaxonomy === 1">
                  <v-select :appendToBody="true" :options="taxonomyKingdomList" v-model="filter.kingdom_id" label="kingdom" index="kingdom_id"
                    :reduce="r => r.kingdom_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.kingdom}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.kingdom}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 2 && filter.fieldTaxonomy === 2">
                  <v-select :appendToBody="true" :options="taxonomyPhyllumList" v-model="filter.phyllum_id" label="phyllum" index="phyllum_id"
                    :reduce="r => r.phyllum_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.phyllum}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.phyllum}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 2 && filter.fieldTaxonomy === 3">
                  <v-select :appendToBody="true" :options="taxonomyClassList" v-model="filter.class_id" label="class" index="class_id"
                    :reduce="r => r.class_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.class}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.class}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 2 && filter.fieldTaxonomy === 4">
                  <v-select :appendToBody="true" :options="taxonomyOrderList" v-model="filter.order_id" label="oorder" index="order_id"
                    :reduce="r => r.order_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.oorder}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.oorder}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 2 && filter.fieldTaxonomy === 5">
                  <v-select :appendToBody="true" :options="taxonomyFamilyList" v-model="filter.family_id" label="family" index="family_id"
                    :reduce="r => r.family_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.family}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.family}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 2 && filter.fieldTaxonomy === 6">
                  <v-select :appendToBody="true" :options="taxonomyGenusList" v-model="filter.genus_id" label="genus" index="genus_id"
                    :reduce="r => r.genus_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.genus}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.genus}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 2 && filter.fieldTaxonomy === 7">
                  <input type="text" class="form-control bg-laranja" v-model="filter.taxonomySynonymousDesc"/>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 2 && filter.fieldTaxonomy === 8">
                  <input type="text" class="form-control bg-laranja" v-model="filter.commonNameDesc"/>
                </div>
                <!-- TAXONOMIA -->

                <!-- ORIGEM -->
                <div class="col-12 col-lg-4" v-if="filter.theme === 3">
                  <v-select :appendToBody="true" :options="listOrigin" v-model="filter.origin" label="text" index="value"
                    :reduce="r => r.value" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <!-- ORIGEM -->

                <!-- Biologia e ecologia -->
                <!--<div class="col-12 col-lg-4" v-if="filter.theme === 4">
                  <v-select :appendToBody="true" :options="listBiologiaEcologia" v-model="filter.fieldBiologiaEcologia" label="text" index="value"
                    :reduce="r => r.value" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 4 && filter.fieldBiologiaEcologia === 1">
                  <v-select :appendToBody="true" :options="vocReproductionList" v-model="filter.reproduction_id" label="value" index="reproduction_id"
                    :reduce="r => r.reproduction_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 4 && filter.fieldBiologiaEcologia === 2">
                  <v-select :appendToBody="true" :options="vocSpreadList" v-model="filter.spread_id" label="value" index="spread_id"
                    :reduce="r => r.spread_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 4 && filter.fieldBiologiaEcologia === 3">
                  <v-select :appendToBody="true" :options="vocDietList" v-model="filter.diet_id" label="value" index="diet_id"
                    :reduce="r => r.diet_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 4 && filter.fieldBiologiaEcologia === 4">
                  <v-select :appendToBody="true" :options="vocFormList" v-model="filter.form_id" label="value" index="form_id"
                    :reduce="r => r.form_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>-->
                <!-- Biologia e ecologia -->

                <!-- Uso econômico -->
                <div class="col-12 col-lg-4" v-if="filter.theme === 5">
                  <v-select :appendToBody="true" :options="vocEconomicalUseList" v-model="filter.economic_use_id" label="value" index="economical_use_id"
                    :reduce="r => r.economical_use_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <!-- Uso econômico -->

                <!-- Invasão biológica -->
                <!--<div class="col-12 col-lg-4" v-if="filter.theme === 6">
                  <v-select :appendToBody="true" :options="listInvasaoBiologia" v-model="filter.fieldInvasaoBiologia" label="text" index="value"
                    :reduce="r => r.value" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 6 && filter.fieldInvasaoBiologia === 1">
                  <v-select :appendToBody="true" :options="vocprefenvironmentsList" v-model="filter.pref_inv_env_id" label="value" index="pref_inv_env_id"
                    :reduce="r => r.pref_inv_env_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 6 && filter.fieldInvasaoBiologia === 2">
                  <v-select :appendToBody="true" :options="vocIntroductionTypeList" v-model="filter.introduction_type_id" label="value" index="introduction_type_id"
                    :reduce="r => r.introduction_type_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 6 && filter.fieldInvasaoBiologia === 3">
                  <v-select :appendToBody="true" :options="vocCauseIntroductionList" v-model="filter.cause_introduction_id" label="value" index="cause_introduction_id"
                    :reduce="r => r.cause_introduction_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 6 && filter.fieldInvasaoBiologia === 4">
                  <input type="text" class="form-control bg-laranja" v-model="filter.location"/>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 6 && filter.fieldInvasaoBiologia === 5">
                  <input type="text" class="form-control bg-laranja" v-mask="'####'" v-model="filter.intro_data"/>
                </div>-->
                <!-- Invasão biológica -->

                <!-- Impactos -->
                <div class="col-12 col-lg-4" v-if="filter.theme === 7">
                  <v-select :appendToBody="true" :options="vocImpactsList" v-model="filter.impact_id" label="value" index="impact_id"
                    :reduce="r => r.impact_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <!-- Impactos -->

                <!-- Ocorrencias -->
                <div class="col-12 col-lg-4" v-if="filter.theme === 8">
                  <v-select :appendToBody="true" :options="listOccurrences" v-model="filter.fieldOccurrence" label="text" index="value"
                    :reduce="r => r.value" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 8 && filter.fieldOccurrence === 1">
                  <v-select :appendToBody="true" :options="statesList" v-model="filter.state_id" label="state" index="state_id"
                    :reduce="r => r.state_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.state}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.state}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 8 && filter.fieldOccurrence === 2">
                  <v-select :appendToBody="true" :filterable="false" @search="getVocMunicipios" :options="vocMunicipiosList" v-model="filter.municipio_id" label="municipio" index="municipio_id"
                    :reduce="r => r.municipio_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.municipio}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.municipio}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="filter.theme === 8 && filter.fieldOccurrence === 3">
                  <input type="text" class="form-control bg-laranja" v-model="filter.protected_area"/>
                </div>
                <!-- Ocorrencias -->
                <!-- Hábitat -->
                <div class="col-12 col-lg-4" v-if="filter.theme === 9">
                  <v-select :appendToBody="true" :options="habitats" v-model="filter.habitat" label="value" index="id"
                    :reduce="r => r.id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <!-- Hábitat -->
                <div class="col-12 col-lg-2 ml-auto text-right pt-1">
                  <button type="button" @click="clear" class="btn btn-outline-secondary mt-1 mb-2">
                    {{ $t('label.limpar') }}
                  </button>
                </div>
              </div>
              <div class="row pl-2 pt-1 pb-0">
                <div class="col-12">
                  <span class="filtros-selecionados" v-if="filter.specieSelected.length > 0">
                    <b class="d-flex justify-content-start">{{$t('label.nomeCientifico')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.specieNameSelected}}</li>
                      <li><a @click="removeFilter('specieSelected')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.taxonomySynonymousDesc">
                    <b class="d-flex justify-content-start">{{$t('label.sinonimos')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.taxonomySynonymousDesc}}</li>
                      <li><a @click="removeFilter('taxonomySynonymousDesc')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.commonNameDesc">
                    <b class="d-flex justify-content-start">{{$t('label.nomesPopulares')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.commonNameDesc}}</li>
                      <li><a @click="removeFilter('commonNameDesc')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.kingdom_id">
                    <b class="d-flex justify-content-start">{{$t('label.reino')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.kingdom}}</li>
                      <li><a @click="removeFilter('kingdom_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.phyllum_id">
                    <b class="d-flex justify-content-start">{{$t('label.phylum')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.phyllum}}</li>
                      <li><a @click="removeFilter('phyllum_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.class_id">
                    <b class="d-flex justify-content-start">{{$t('label.classe')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.class}}</li>
                      <li><a @click="removeFilter('class_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.order_id">
                    <b class="d-flex justify-content-start">{{$t('label.ordem')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.oorder}}</li>
                      <li><a @click="removeFilter('order_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.family_id">
                    <b class="d-flex justify-content-start">{{$t('label.familia')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.family}}</li>
                      <li><a @click="removeFilter('family_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.genus_id">
                    <b class="d-flex justify-content-start">{{$t('label.genero')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.genus}}</li>
                      <li><a @click="removeFilter('genus_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.origin">
                    <b class="d-flex justify-content-start">{{$t('label.origem')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.originDesc}}</li>
                      <li><a @click="removeFilter('origin')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.reproduction_id">
                    <b class="d-flex justify-content-start">{{$t('label.reproducao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.reproductionDesc}}</li>
                      <li><a @click="removeFilter('reproduction_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.spread_id">
                    <b class="d-flex justify-content-start">{{$t('label.dispersao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.spreadDesc}}</li>
                      <li><a @click="removeFilter('spread_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.diet_id">
                    <b class="d-flex justify-content-start">{{$t('label.dieta')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.dietDesc}}</li>
                      <li><a @click="removeFilter('diet_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.form_id">
                    <b class="d-flex justify-content-start">{{$t('label.formaBiologica')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.formDesc}}</li>
                      <li><a @click="removeFilter('form_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.economic_use_id">
                    <b class="d-flex justify-content-start">{{$t('label.usoEconomico')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.economicUseDesc}}</li>
                      <li><a @click="removeFilter('economic_use_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.pref_inv_env_id">
                    <b class="d-flex justify-content-start">{{$t('label.ambientesPreferenciaisInvasao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.prefInvEnvDesc}}</li>
                      <li><a @click="removeFilter('pref_inv_env_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.introduction_type_id">
                    <b class="d-flex justify-content-start">{{$t('label.tipoIntroducao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.introductionTypeDesc}}</li>
                      <li><a @click="removeFilter('introduction_type_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.cause_introduction_id">
                    <b class="d-flex justify-content-start">{{$t('label.causaIntroducao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.causeIntroductionDesc}}</li>
                      <li><a @click="removeFilter('cause_introduction_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.location">
                    <b class="d-flex justify-content-start">{{$t('label.localIntroducao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.location}}</li>
                      <li><a @click="removeFilter('location')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.intro_data">
                    <b class="d-flex justify-content-start">{{$t('label.anoIntroducao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.intro_data}}</li>
                      <li><a @click="removeFilter('intro_data')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.impact_id">
                    <b class="d-flex justify-content-start">{{$t('label.impactos')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.impactDesc}}</li>
                      <li><a @click="removeFilter('impact_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.state_id">
                    <b class="d-flex justify-content-start">{{$t('label.estado')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.state}}</li>
                      <li><a @click="removeFilter('state_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.municipio_id">
                    <b class="d-flex justify-content-start">{{$t('label.municipio')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.municipio}}</li>
                      <li><a @click="removeFilter('municipio_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.protected_area">
                    <b class="d-flex justify-content-start">{{$t('label.areaProtegida')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.protected_area}}</li>
                      <li><a @click="removeFilter('protected_area')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.habitat">
                    <b class="d-flex justify-content-start">{{$t('label.habitat')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.habitatDesc}}</li>
                      <li><a @click="removeFilter('habitat')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.pathways_cdb_id">
                    <b class="d-flex justify-content-start">{{$t('label.categoriaCDB')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.pathwaysCdbDesc}}</li>
                      <li><a @click="removeFilter('pathways_cdb_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.route_id">
                    <b class="d-flex justify-content-start">{{$t('label.viasIntroducao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.routeDesc}}</li>
                      <li><a @click="removeFilter('route_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.vectors_cdb_id">
                    <b class="d-flex justify-content-start">{{$t('label.subCategoriaCDB')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.vectorsCdbDesc}}</li>
                      <li><a @click="removeFilter('vectors_cdb_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="filter.vector_id">
                    <b class="d-flex justify-content-start">{{$t('label.vetoresIntroducao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{filter.vectorDesc}}</li>
                      <li><a @click="removeFilter('vector_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                </div>
              </div>
            </form>

            <div class="row mt-1 mt-xl-2 p-2 bg_table_header" id="tut-num-registro1">
              <div class="col-12 d-sm-block col-export">
                <p class="text-danger h5"><strong>{{ $t('label.formatosDisponiveisExportacao') }}</strong></p>
                <ul class="export_type">
                  <li><a @click="onexport('csv')"><img src="../../../../assets/institucional/img/icon/csv-file-format-extension.svg" alt="Exportar em planilha CSV"></a></li>
                  <li><a @click="onexport('xlsx')"><img src="../../../../assets/institucional/img/icon/xls-file-format-symbol.svg" alt="Exportar em planilha XLSX"></a></li>
                  <li><a @click="onexportDocx"><img src="../../../../assets/institucional/img/icon/doc-file-format.svg" alt="Exportar em Documento Word"></a></li>
                  <!--li><a @click="onexportPdf"><img src="../../../../assets/institucional/img/icon/pdf-file.svg" alt="Exportar em Pdf"></a></li-->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import XLSX from 'xlsx'
export default {
  name: 'FichasFiltroMMA2',
  data: function () {
    return {
      filter: {
        theme: null,
        fieldTaxonomy: 1,
        fieldBiologiaEcologia: 1,
        fieldInvasaoBiologia: 1,
        fieldOccurrence: 1,
        specieSelected: []
      },
      listThemes: [
        { value: null, text: this.$t('label.filtros') },
        { value: 1, text: this.$t('label.nomeCientifico') },
        { value: 2, text: this.$t('label.taxonomia') },
        { value: 3, text: this.$t('label.origem') },
        // { value: 4, text: this.$t('label.biologiaEcologia') },
        { value: 5, text: this.$t('label.usoEconomico') },
        // { value: 6, text: this.$t('label.invasaoBiologica') },
        { value: 7, text: this.$t('label.impactos') },
        { value: 8, text: this.$t('label.ocorrencias') },
        { value: 9, text: this.$t('label.habitat') }
      ],
      listTaxonomy: [
        { value: 1, text: this.$t('label.reino') },
        { value: 2, text: this.$t('label.phylum') },
        { value: 3, text: this.$t('label.classe') },
        { value: 4, text: this.$t('label.ordem') },
        { value: 5, text: this.$t('label.familia') },
        { value: 6, text: this.$t('label.genero') },
        { value: 7, text: this.$t('label.nomeCientificoSinonimos') }
        // { value: 8, text: this.$t('label.nomesPopulares') }
      ],
      taxonomyGenusList: [],
      taxonomyKingdomList: [],
      taxonomyPhyllumList: [],
      taxonomyOrderList: [],
      listOrigin: [
        { value: 'Non-native', text: this.$i18n.locale === 'en' ? 'Not native in the country' : this.$i18n.locale === 'es' ? 'No nativa del país' : 'Não nativa do país' },
        { value: 'Native', text: this.$i18n.locale === 'en' ? 'Native in the country' : this.$i18n.locale === 'es' ? 'Nativa del país' : 'Nativa do país' },
        { value: 'Cripto', text: this.$i18n.locale === 'en' ? 'Origin unknown' : this.$i18n.locale === 'es' ? 'Origen desconocido' : 'Origem desconhecida' },
        { value: 'Hybrid', text: this.$i18n.locale === 'en' ? 'Hybrid, no native range' : this.$i18n.locale === 'es' ? 'Híbrido sin  distribución natural' : 'Híbrido sem distribuição natural' }
      ],
      listBiologiaEcologia: [
        { value: 1, text: this.$t('label.reproducao') },
        { value: 2, text: this.$t('label.dispersao') },
        { value: 3, text: this.$t('label.dieta') },
        { value: 4, text: this.$t('label.formaBiologica') }
      ],
      listInvasaoBiologia: [
        { value: 1, text: this.$t('label.ambientesPreferenciaisInvasao') },
        // { value: 2, text: this.$t('label.tipoIntroducao') },
        { value: 3, text: this.$t('label.causaIntroducao') },
        { value: 4, text: this.$t('label.localIntroducao') },
        { value: 5, text: this.$t('label.anoIntroducao') }
      ],
      listOccurrences: [
        { value: 1, text: this.$t('label.estado') },
        { value: 2, text: this.$t('label.municipio') },
        { value: 3, text: this.$t('label.areaProtegida') }
      ],
      vocDietList: [],
      vocSpreadList: [],
      vocReproductionList: [],
      vocFormList: [],
      vocEconomicalUseList: [],
      vocprefenvironmentsList: [],
      vocIntroductionTypeList: [],
      vocCauseIntroductionList: [],
      vocImpactsList: [],
      vocMunicipiosList: [],
      statesList: [],
      taxonomyClassList: [],
      formSearch: {},
      habitats: [
        { id: null, value: this.$t('label.selecioneOpcao') },
        { id: 1, value: this.$t('label.marinho') },
        { id: 2, value: this.$t('label.dulcicola') },
        { id: 3, value: this.$t('label.terrestre') }
      ],
      speciesList: []
    }
  },
  watch: {
    'filter.municipio_id' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getVocMunicipios()
      }
      if (newValue) {
        const list = this.vocMunicipiosList.filter(l => l.municipio_id === newValue)
        if (list.length > 0) {
          this.filter.municipio = list[0].municipio
        }
      }
    },
    'filter.specieSelected' (newValue) {
      const desc = []
      newValue.forEach(item => {
        const list = this.speciesList.filter(l => l.species_id === item)
        if (list.length > 0) {
          desc.push(list[0].scientific_name)
        }
      })
      this.filter.specieNameSelected = desc.join(', ')
    },
    'filter.kingdom_id' (newValue) {
      const list = this.taxonomyKingdomList.filter(l => l.kingdom_id === newValue)
      if (list.length > 0) {
        this.filter.kingdom = list[0].kingdom
      }
      this.getTaxonomyPhyllum()
    },
    'filter.phyllum_id' (newValue) {
      const list = this.taxonomyPhyllumList.filter(l => l.phyllum_id === newValue)
      if (list.length > 0) {
        this.filter.phyllum = list[0].phyllum
      }
      this.getTaxonomyClass()
    },
    'filter.class_id' (newValue) {
      const list = this.taxonomyClassList.filter(l => l.class_id === newValue)
      if (list.length > 0) {
        this.filter.class = list[0].class
      }
      this.getTaxonomyOrder()
    },
    'filter.order_id' (newValue) {
      const list = this.taxonomyOrderList.filter(l => l.order_id === newValue)
      if (list.length > 0) {
        this.filter.oorder = list[0].oorder
      }
      this.getTaxonomyFamily()
    },
    'filter.family_id' (newValue) {
      const list = this.taxonomyFamilyList.filter(l => l.family_id === newValue)
      if (list.length > 0) {
        this.filter.family = list[0].family
      }
      this.getTaxonomyGenus()
    },
    'filter.genus_id' (newValue) {
      const list = this.taxonomyGenusList.filter(l => l.genus_id === newValue)
      if (list.length > 0) {
        this.filter.genus = list[0].genus
      }
    },
    'filter.origin' (newValue) {
      const list = this.listOrigin.filter(l => l.value === newValue)
      if (list.length > 0) {
        this.filter.originDesc = list[0].text
      }
    },
    'filter.reproduction_id' (newValue) {
      const list = this.vocReproductionList.filter(l => l.reproduction_id === newValue)
      if (list.length > 0) {
        this.filter.reproductionDesc = list[0].value
      }
    },
    'filter.spread_id' (newValue) {
      const list = this.vocSpreadList.filter(l => l.spread_id === newValue)
      if (list.length > 0) {
        this.filter.spreadDesc = list[0].value
      }
    },
    'filter.diet_id' (newValue) {
      const list = this.vocDietList.filter(l => l.diet_id === newValue)
      if (list.length > 0) {
        this.filter.dietDesc = list[0].value
      }
    },
    'filter.form_id' (newValue) {
      const list = this.vocFormList.filter(l => l.form_id === newValue)
      if (list.length > 0) {
        this.filter.formDesc = list[0].value
      }
    },
    'filter.economic_use_id' (newValue) {
      const list = this.vocEconomicalUseList.filter(l => l.economical_use_id === newValue)
      if (list.length > 0) {
        this.filter.economicUseDesc = list[0].value
      }
    },
    'filter.pref_inv_env_id' (newValue) {
      const list = this.vocprefenvironmentsList.filter(l => l.pref_inv_env_id === newValue)
      if (list.length > 0) {
        this.filter.prefInvEnvDesc = list[0].value
      }
    },
    'filter.introduction_type_id' (newValue) {
      const list = this.vocIntroductionTypeList.filter(l => l.introduction_type_id === newValue)
      if (list.length > 0) {
        this.filter.introductionTypeDesc = list[0].value
      }
    },
    'filter.cause_introduction_id' (newValue) {
      const list = this.vocCauseIntroductionList.filter(l => l.cause_introduction_id === newValue)
      if (list.length > 0) {
        this.filter.causeIntroductionDesc = list[0].value
      }
    },
    'filter.impact_id' (newValue) {
      const list = this.vocImpactsList.filter(l => l.impact_id === newValue)
      if (list.length > 0) {
        this.filter.impactDesc = list[0].value
      }
    },
    'filter.state_id' (newValue) {
      const list = this.statesList.filter(l => l.state_id === newValue)
      if (list.length > 0) {
        this.filter.state = list[0].state
      }
    },
    'filter.habitat' (newValue) {
      const list = this.habitats.filter(l => l.id === newValue)
      if (list.length > 0) {
        this.filter.habitatDesc = list[0].value
      }
    },
    'filter.pathways_cdb_id' (newValue) {
      const list = this.vocPathwaysCdbList.filter(l => l.pathways_cdb_id === newValue)
      if (list.length > 0) {
        this.filter.pathwaysCdbDesc = list[0].value
      }
    },
    'filter.route_id' (newValue) {
      const list = this.vocDispersalRoutesList.filter(l => l.route_id === newValue)
      if (list.length > 0) {
        this.filter.routeDesc = list[0].value
      }
    },
    'filter.vectors_cdb_id' (newValue) {
      const list = this.vocVectorsCdbList.filter(l => l.vectors_cdb_id === newValue)
      if (list.length > 0) {
        this.filter.vectorsCdbDesc = list[0].value
      }
    },
    'filter.vector_id' (newValue) {
      const list = this.vocDispersionVectorsList.filter(l => l.vector_id === newValue)
      if (list.length > 0) {
        this.filter.vectorDesc = list[0].value
      }
    }
  },
  methods: {
    clear: function () {
      this.filter = {
        theme: 1,
        fieldTaxonomy: 1,
        fieldBiologiaEcologia: 1,
        fieldInvasaoBiologia: 1,
        fieldOccurrence: 1,
        specieSelected: []
      }
    },
    getSpecies: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 9999
      }
      if (search && search.length > 2) {
        params.scientific_name = search
        params.species_id_array = this.filter.specieSelected.join(',')
        loading(true)
        axios.get('/Specie/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            loading(false)
          })
      } else if (!search) {
        params.species_id_array = this.filter.specieSelected.join(',')
        axios.get('/Specie/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
          })
      }
    },
    onexport: async function (type) {
      const items = await this.datasheet()
      var especiesWS = XLSX.utils.json_to_sheet(items, {
        header: [
          'kingdom',
          'phyllum',
          'class',
          'oorder',
          'family',
          'scientific_name',
          'author',
          'common_name',
          'morphophysiology_description',
          'voc_form',
          'voc_reproduction',
          'voc_spread',
          'voc_diet',
          'origin',
          'native_distribution_area',
          'natural_environment',
          'world_invasive_place',
          'invasion_preferential_environments',
          'economic_use',
          'biodiversity_impact',
          'economic_impact',
          'social_impact',
          'health_impact',
          'prevention',
          'physical_control',
          'chemical_control',
          'control_biological',
          'voc_dispersal_routes',
          'voc_dispersion_vectors',
          'intro_data',
          'location',
          'intro_description',
          'voc_introduction_type',
          'voc_cause_introduction',
          'data_bibliography'
        ]
      })
      var wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, especiesWS, 'Sheet')
      XLSX.writeFile(wb, `BD-fichas-especies.${type}`)
    },
    getTaxonomyKingdom: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/TaxonomyKingdom/getall', { params })
        .then(response => {
          this.taxonomyKingdomList = response.data.items
        })
    },
    getTaxonomyPhyllum: function () {
      const params = { Page: 1, PageSize: 9999, kingdom_id: this.filter.kingdom_id }
      axios.get('/TaxonomyPhyllum/getall', { params })
        .then(response => {
          this.taxonomyPhyllumList = response.data.items
        })
    },
    getTaxonomyClass: function () {
      const params = { Page: 1, PageSize: 9999, phyllum_id: this.filter.phyllum_id }
      axios.get('/TaxonomyClass/getall', { params })
        .then(response => {
          this.taxonomyClassList = response.data.items
        })
    },
    getTaxonomyOrder: function () {
      const params = { Page: 1, PageSize: 9999, class_id: this.filter.class_id }
      axios.get('/TaxonomyOrder/getall', { params })
        .then(response => {
          this.taxonomyOrderList = response.data.items
        })
    },
    getTaxonomyFamily: function () {
      const params = { Page: 1, PageSize: 9999, order_id: this.filter.order_id }
      axios.get('/TaxonomyFamily/getall', { params })
        .then(response => {
          this.taxonomyFamilyList = response.data.items
        })
    },
    getTaxonomyGenus: function () {
      const params = { Page: 1, PageSize: 9999, family_id: this.filter.family_id }
      axios.get('/TaxonomyGenus/getall', { params })
        .then(response => {
          this.taxonomyGenusList = response.data.items
        })
    },
    getVocForm: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocform/getall', { params })
        .then(response => {
          this.vocFormList = response.data.items.map(i => {
            const item = {
              form_id: i.form_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocReproduction: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocreproduction/getall', { params })
        .then(response => {
          this.vocReproductionList = response.data.items.map(i => {
            const item = {
              reproduction_id: i.reproduction_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocSpread: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocspread/getall', { params })
        .then(response => {
          this.vocSpreadList = response.data.items.map(i => {
            const item = {
              spread_id: i.spread_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocDiet: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocDiet/getall', { params })
        .then(response => {
          this.vocDietList = response.data.items.map(i => {
            const item = {
              diet_id: i.diet_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocEconomicalUse: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/voceconomicaluse/getall', { params })
        .then(response => {
          this.vocEconomicalUseList = response.data.items.map(i => {
            const item = {
              economical_use_id: i.economical_use_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocPrefEnvironments: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocprefenvironments/getall', { params })
        .then(response => {
          this.vocprefenvironmentsList = response.data.items.map(i => {
            const item = {
              pref_inv_env_id: i.pref_inv_env_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocIntroductionType: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocIntroductionType/getall', { params })
        .then(response => {
          this.vocIntroductionTypeList = response.data.items.map(i => {
            const item = {
              introduction_type_id: i.introduction_type_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocCauseIntroduction: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocCauseIntroduction/getall', { params })
        .then(response => {
          this.vocCauseIntroductionList = response.data.items.map(i => {
            const item = {
              cause_introduction_id: i.cause_introduction_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocImpacts: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/vocimpacts/getall', { params })
        .then(response => {
          this.vocImpactsList = response.data.items.map(i => {
            const item = {
              impact_id: i.impact_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getStates: function () {
      const params = { Page: 1, PageSize: 9999 }
      axios.get('/VocStates/getall', { params })
        .then(response => {
          this.statesList = response.data.items
        })
    },
    getVocMunicipios: function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 20
      }
      if (search && search.length > 2) {
        params.municipio = search
        loading(true)
        axios.get('/VocMunicipios/getall', { params })
          .then(response => {
            this.vocMunicipiosList = response.data.items
            loading(false)
          })
      } else if (!search) {
        params.municipio_id = this.filter.municipio_id
        axios.get('/VocMunicipios/getall', { params })
          .then(response => {
            this.vocMunicipiosList = response.data.items
          })
      }
    },
    setFormSearch: function () {
      if (this.filter.specieSelected) {
        this.formSearch.species_id_array = this.filter.specieSelected.join(',')
      }
      this.formSearch.kingdom_id = this.filter.kingdom_id
      this.formSearch.phyllum_id = this.filter.phyllum_id
      this.formSearch.class_id = this.filter.class_id
      this.formSearch.order_id = this.filter.order_id
      this.formSearch.family_id = this.filter.family_id
      this.formSearch.genus_id = this.filter.genus_id
      this.formSearch.origin = this.filter.origin
      this.formSearch.reproduction_id = this.filter.reproduction_id
      this.formSearch.spread_id = this.filter.spread_id
      this.formSearch.diet_id = this.filter.diet_id
      this.formSearch.biological_form_id = this.filter.form_id
      this.formSearch.economic_use_id = this.filter.economic_use_id
      this.formSearch.pref_inv_env_id = this.filter.pref_inv_env_id
      this.formSearch.introduction_type_id = this.filter.introduction_type_id
      this.formSearch.cause_introduction_id = this.filter.cause_introduction_id
      this.formSearch.location = this.filter.location
      this.formSearch.intro_data = this.filter.intro_data
      this.formSearch.impact_id = this.filter.impact_id
      this.formSearch.state_id = this.filter.state_id
      this.formSearch.municipio_id = this.filter.municipio_id
      this.formSearch.protected_area = this.filter.protected_area
      this.formSearch.habitat = this.filter.habitat
      this.formSearch.Common_name = this.filter.commonNameDesc
      this.formSearch.synonymous = this.filter.taxonomySynonymousDesc
      this.formSearch.pathways_cdb_id = this.filter.pathways_cdb_id
      this.formSearch.route_id = this.filter.route_id
      this.formSearch.vectors_cdb_id = this.filter.vectors_cdb_id
      this.formSearch.vector_id = this.filter.vector_id
      if (this.byInstitucional) {
        this.formSearch.species_type = 1
      } else {
        this.formSearch.species_type = this.filter.species_type
      }
    },
    datasheet: async function () {
      this.$store.dispatch('showPreload')
      this.setFormSearch()
      const params = this.formSearch
      const response = await axios.get('/ExportData/Datasheet', { params })
      return response.data
    },
    onexportDocx: function () {
      this.$store.dispatch('showPreload')
      this.setFormSearch()
      const params = this.formSearch
      axios({
        url: '/ExportData/datasheetmma2docx',
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Access-Control-Allow-Origin': '*'
        },
        params
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'FichasEspecies.zip')
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    onexportPdf: function () {
      this.$store.dispatch('showPreload')
      this.setFormSearch()
      const params = this.formSearch
      axios({
        url: '/ExportData/datasheetpdf',
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Access-Control-Allow-Origin': '*'
        },
        params
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'FichasEspeciesPdf.zip')
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    }
  },
  mounted: function () {
    this.getTaxonomyKingdom()
    this.getTaxonomyPhyllum()
    this.getTaxonomyClass()
    this.getTaxonomyOrder()
    this.getTaxonomyFamily()
    this.getTaxonomyGenus()
    this.getVocForm()
    this.getVocReproduction()
    this.getVocSpread()
    this.getVocDiet()
    this.getVocEconomicalUse()
    this.getVocPrefEnvironments()
    this.getVocIntroductionType()
    this.getVocCauseIntroduction()
    this.getVocImpacts()
    this.getStates()
    this.getVocMunicipios()
    this.getSpecies()
  }
}
</script>
